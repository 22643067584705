<template>
  <table class="table" 
         id="op">
    <thead>
      <tr>
        <th class="th-blue">Libellé</th>
        <th class="th-blue text-right">Montant</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td scope="row" 
            colspan="2"><strong>ACTIFS IMMOBILISES</strong></td>
      </tr>
      <tr>
        <td>Immobilisations incorporelles</td>
        <td class="text-right" 
            v-if="bilanValue.immobilisation_incorporelle">
          {{Math.round(Number(bilanValue.immobilisation_incorporelle)).toLocaleString()}}
        </td>
        <td class="text-right"
            v-else>0</td>
      </tr>
      <tr>
        <td>Immobilisations corporelles</td>
        <td class="text-right"
            v-if="bilanValue.immobilisation_corporelle">
          {{Math.round(Number(bilanValue.immobilisation_corporelle)).toLocaleString()}}
        </td>
        <td class="text-right"
            v-else>0</td>
      </tr>
      <tr>
        <td>Immobilisations financières</td>
        <td class="text-right"
            v-if="bilanValue.immobilisation_financiere">
          {{Math.round(Number(bilanValue.immobilisation_financiere)).toLocaleString()}}
        </td>
        <td class="text-right"
            v-else>0</td>
      </tr>
      <tr>
        <td scope="row" 
            colspan="2"><strong>ACTIFS CIRCULANTS</strong></td>
      </tr>
      <tr>
        <td>Stocks</td>
        <td class="text-right"
            v-if="bilanValue.stock">
          {{Math.round(Number(bilanValue.stock)).toLocaleString()}}
        </td>
        <td class="text-right"
            v-else>0</td>
      </tr>
      <tr>
        <td>Créances</td>
        <td class="text-right"
            v-if="bilanValue.creance">{{Math.round(Number(bilanValue.creance)).toLocaleString()}}</td>
        <td class="text-right"
            v-else>0</td>
      </tr>
      <tr>
        <td>Disponibilités</td>
        <td class="text-right"
            v-if="bilanValue.disponibilite">
          {{Math.round(Number(bilanValue.disponibilite)).toLocaleString()}}
        </td>
        <td class="text-right"
            v-else>0</td>
      </tr>
      <tr class="tht-dark-blue">
        <td scope="row">TOTAL ACTIFS</td>
        <td class="text-right">{{Math.round(total_actif).toLocaleString()}}</td>
      </tr>
      <tr>
        <td scope="row" 
            colspan="2"
            class="text-right"></td>
      </tr>
      <tr>
        <td scope="row" 
            colspan="2"><strong>CAPITAUX PROPRES ET ASSIMILES</strong></td>
      </tr>
      <tr>
        <td>Capitaux propres</td>
        <td class="text-right"
            v-if="bilanValue.capitaux_propre">
          {{Math.round(Number(bilanValue.capitaux_propre)).toLocaleString()}}
        </td>
        <td class="text-right"
            v-else>0</td>
      </tr>
      <tr>
        <td scope="row" 
            colspan="2"><strong>PASSIFS CIRCULANTS</strong></td>
      </tr>
      <tr>
        <td>Dettes financières</td>
        <td class="text-right"
            v-if="bilanValue.dette_financiere">
          {{Math.round(Number(bilanValue.dette_financiere)).toLocaleString()}}
        </td>
        <td class="text-right"
            v-else>0</td>
      </tr>
      <tr>
        <td>Dettes d'exploitation</td>
        <td class="text-right"
            v-if="bilanValue.dette_exploitation">
          {{Math.round(Number(bilanValue.dette_exploitation)).toLocaleString()}}
        </td>
        <td class="text-right"
            v-else>0</td>
      </tr>
      <tr>
        <td>Dettes sur Immobilisations</td>
        <td class="text-right"
            v-if="bilanValue.dette_immobilisation">
          {{Math.round(Number(bilanValue.dette_immobilisation)).toLocaleString()}}
        </td>
        <td class="text-right"
            v-else>0</td>
      </tr>
      <tr>
        <td>Autres dettes</td>
        <td class="text-right"
            v-if="bilanValue.autre_dette">
          {{Math.round(Number(bilanValue.autre_dette)).toLocaleString()}}
        </td>
        <td class="text-right"
            v-else>0</td>
      </tr>
      <tr class="tht-dark-blue">
        <td scope="row">TOTAL PASSIFS</td>
        <td class="text-right">{{Math.round(total_passif).toLocaleString()}}</td>
      </tr>
    </tbody>
  </table>  
</template>
<style scoped>
#op{
  border: none;
  font-size: 0.7em;
  height: 5vh;
  background: none;
}
</style>
<script>
import { mapActions,mapGetters,mapMutations } from "vuex"

export default ({
  name:"BilanCG",
  props:["year"],
  data:()=>({
    bilanData:{
      annee_debut:"",
      annee_fin:"",
      base:""
    },
    bilanValue:"",
    total_actif:0,
    total_passif:0,
  }),
  watch:{
    bilanCG(){
      if (this.bilanCG.donnees[0]) {
        console.log(this.bilanCG.donnees[0])
        this.bilanValue=this.bilanCG.donnees[0]
        this.total_actif = Number(this.bilanValue.disponibilite) + 
          Number(this.bilanValue.creance) + Number(this.bilanValue.stock) + 
          Number(this.bilanValue.immobilisation_financiere) + 
          Number(this.bilanValue.immobilisation_corporelle) + 
          Number(this.bilanValue.immobilisation_incorporelle)
        this.total_actif = Math.round(this.total_actif)

        this.total_passif = Number(this.bilanValue.dette_exploitation) + 
          Number(this.bilanValue.dette_financiere) + 
          Number(this.bilanValue.capitaux_propre)
        this.total_passif = Math.round(this.total_passif)
      }
    }
  },
  created(){
    this.bilanData.annee_debut= this.year.annee_debut
    this.bilanData.annee_fin= this.year.annee_fin
    this.loadBilanCG(this.bilanData)
  },
  computed:{
    ...mapGetters(["bilanCG","msgFailBilan"])
  },
  methods:{
    ...mapActions(["loadBilanCG",]),
    ...mapMutations(["setBilanCG","setFailBilanCG"]),
  },
})
</script>
