<template>
  <div>
    <div class="row my-4">
      <div class="col-md-2">
        <button class="btn btn-round btn-blue"
                @click="generateReport()"> Imprimer </button>
      </div>
      <form class="form-inline mx-auto">
        <div class="form-group">
          <label for="">Classe: </label>
          <select class="form-control mx-2" 
                  name="" 
                  id=""
                  v-model="JournalData.classe">
            <option value="1">Classe 1</option>
            <option value="2">Classe 2</option>
            <option value="3">Classe 3</option>
            <option value="4">Classe 4</option>
            <option value="5">Classe 5</option>
            <option value="6">Classe 6</option>
            <option value="7">Classe 7</option>
            <option value="8">Classe 8</option>
            <option value="">Tout</option>
          </select>
        </div>
        <button type="button" 
                class="btn btn-primary"
                @click="search()">Rechercher</button>
      </form>
      <div class="div_left">
        <button class="btn btn-blue pl-5 pr-5 op-btn"
                data-toggle="modal"
                ref="modal_button"
                data-target="#filtreModal">Filtrer</button>
      </div>
    </div>
    <!-- ///////////////////////////////////////////////////////////////////////// ZONE IMPRIMABLE ///////////////////////////////////////////////////////////////////////////////////////// -->
    <!-- Vue-html2pdf -->
    <vue-html2pdf :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="true"
                  :paginate-elements-by-height="1400"
                  :filename="'Suivi_budgetaire_'+JournalData.base+'_'+dateJour"
                  :pdf-quality="2"
                  :manual-pagination="false"
                  pdf-format="a4"
                  pdf-orientation="portrait"
                  pdf-content-width="800px"

                  @progress="onProgress($event)"
                  @hasStartedGeneration="hasStartedGeneration()"
                  @hasGenerated="hasGenerated($event)"
                  ref="html2Pdf"
                  v-if="loaded==true">
      <div slot="pdf-content">
        <div class="row mt-3">
          <div class="col-3 ml-4">
            <img src="../../../assets/img/logo.svg" />
          </div>
          <div class="col-6">
          </div>
          <div class="col-2 ml-4">
            {{ dateJour }}
          </div>
        </div>
        <div class="row justify-content-center mt-4">
          <div class="col-8">
            <div class="card title-card">
              <div class="card-body text-center title">
                Suivi Budgétaire {{ JournalData.base }}<br>
                du {{ dateJour }} 
              </div>
            </div>
          </div>
        </div>
        <div style="width: 95%;margin: auto;"
             v-if="loaded">
          <table class="table table-striped">
            <thead>
              <tr>
                <th class="th-blue"
                    style="width:10%">Compte</th>
                <th class="th-blue"
                    style="width:30%">Libellé</th>
                <th class="th-blue text-right"
                    style="width:10%">Budget</th>
                <th class="th-blue text-right"
                    style="width:10%">Réalisation</th>
                <th class="th-blue text-right"
                    style="width:10%">Ecart</th>
              </tr>
            </thead>
            <tbody v-if="!loaded">
              <tr>
                <td scope="row"
                    class="text-center"
                    colspan="5">Chargement en cours...</td>
              </tr>
            </tbody>
            <tbody v-else-if="loaded && comptes.donnees.length > 0">
              <tr v-for="(compte,compkey) in comptes.donnees"
                  :key="compkey">
                <td v-if="compte.compte!=null">{{compte.compte}}</td>
                <td v-else
                    class="txt-blue">999999999</td>
                <td>{{compte.libelle}}</td>
                <td class="text-right">{{Math.round(Number(compte.budget_definitif)).toLocaleString()}}</td>
                <td class="text-right">{{Math.round(Number(compte.realisation)).toLocaleString()}}</td>
                <td class="text-right"
                    :class="compte.color"
                    v-if="user=='DCG'||user=='ADMIN'">
                  {{Math.round(compte.ecart).toLocaleString()}}  
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td scope="row"
                    class="text-center"
                    colspan="5">Aucune donnée enregistrée.</td>
              </tr>
            </tbody>
            <tfoot>
              <tr >
                <td class="tht-blue"
                    colspan="2">TOTAL</td>
                <td class="text-right tht-blue">{{Math.round(Number(total.budget_definitif)).toLocaleString()}}</td>
                <td class="text-right tht-blue">{{Math.round(Number(total.realisation)).toLocaleString()}}</td>
                <td class="text-right tht-blue"
                    v-if="user=='DCG'||user=='ADMIN'">
                  {{Math.round(total.ecart).toLocaleString()}}  
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </vue-html2pdf>
    <!-- ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->
    <table class="table table-striped">
      <thead>
        <tr>
          <th class="th-blue"
              style="width:10%">Compte</th>
          <th class="th-blue"
              style="width:30%">Libellé</th>
          <th class="th-blue text-right"
              style="width:10%">Budget initial</th>
          <th class="th-blue text-right"
              style="width:10%"
              v-if="user=='DCG'||user=='ADMIN'">Ajustement budget</th>
          <th class="th-blue text-right"
              style="width:10%">Budget définitif</th>
          <th class="th-blue text-right"
              style="width:10%">Réalisation</th>
          <th class="th-blue text-right"
              style="width:10%"
              v-if="user=='DCG'||user=='ADMIN'">Ajustement réalisation</th>
          <th class="th-blue text-right"
              style="width:10%">Ecart</th>
          <!-- <th class="th-blue text-right"
              style="width:10%">Crédit</th> -->
          <th class="th-blue text-center"
              style="width:10%"
              v-if="user=='DCG'||user=='ADMIN'">
            Action
          </th>
        </tr>
      </thead>
      <tbody v-if="!loaded">
        <tr>
          <td scope="row"
              class="text-center"
              colspan="10">Chargement en cours...</td>
        </tr>
      </tbody>
      <tbody v-else-if="loaded && comptes.donnees.length > 0">
        <tr v-for="(compte,compkey) in comptes.donnees"
            :key="compkey">
          <td v-if="compte.compte!=null">{{compte.compte}}</td>
          <td v-else
              class="txt-blue">999999999</td>
          <td>{{compte.libelle}}</td>
          <td class="text-right">{{Math.round(Number(compte.budget)).toLocaleString()}}</td>
          
          <td class="text-right"
              v-if="user=='DCG'||user=='ADMIN'">
            {{Math.round(Number(compte.ajustement_budget)).toLocaleString()}}
          </td>
          <td class="text-right">{{Math.round(Number(compte.budget_definitif)).toLocaleString()}}</td>
          <td class="text-right">{{Math.round(Number(compte.realisation)).toLocaleString()}}</td>
          <td class="text-right"
              v-if="user=='DCG'||user=='ADMIN'">
            {{Math.round(compte.ajustement_realisation).toLocaleString()}}
          </td>
          <td class="text-right"
              :class="compte.color"
              v-if="user=='DCG'||user=='ADMIN'">
            {{Math.round(compte.ecart).toLocaleString()}}  
            
          </td>
          <!-- <td class="text-right">{{Math.round(Number(compte.debit)).toLocaleString()}}</td>
          <td class="text-right">{{Math.round(Number(compte.credit)).toLocaleString()}}</td> -->
          <td class="text-center"
              v-if="user=='DCG'||user=='ADMIN'">
            <a class="btn btn-blue px-3 ajust"
               data-toggle="modal"
               ref="modal_button"
               data-target="#exampleModal2"
               @click="ajustement(compte)">Ajuster</a></td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td scope="row"
              class="text-center"
              colspan="10">Aucune donnée enregistrée.</td>
        </tr>
      </tbody>
      <tfoot>
        <tr >
          <td class="tht-blue"
              colspan="2">TOTAL</td>
          <td class="text-right tht-blue">{{Math.round(Number(total.budget)).toLocaleString()}}</td>
          
          <td class="text-right tht-blue"
              v-if="user=='DCG'||user=='ADMIN'">
            {{Math.round(Number(total.ajustement_budget)).toLocaleString()}}
          </td>
          <td class="text-right tht-blue">{{Math.round(Number(total.budget_definitif)).toLocaleString()}}</td>
          <td class="text-right tht-blue">{{Math.round(Number(total.realisation)).toLocaleString()}}</td>
          <td class="text-right tht-blue"
              v-if="user=='DCG'||user=='ADMIN'">
            {{Math.round(Number(total.ajustement_realisation)).toLocaleString()}}
          </td>
          <td class="text-right tht-blue"
              v-if="user=='DCG'||user=='ADMIN'">
            {{Math.round(total.ecart).toLocaleString()}}  
          </td>
          <td class="tht-blue"></td>
        </tr>
      </tfoot>
    </table>
    <div class="row pagination">
      <div v-if="comptes.pagination"
           class="col-md-2 mx-auto text-center">
        <span v-if="comptes.pagination.precedent"
              class="badge badge-pill badge-info"
              @click="pIndex--, paging(comptes.pagination.precedent)"> &#60; </span>
        {{ pIndex }}
        <span v-if="comptes.pagination.suivant"
              class="badge badge-pill badge-info"
              @click="pIndex++, paging(comptes.pagination.suivant)"> &#62; </span>
      </div>
    </div>
    <!-- Modal filtre-->
    <div class="modal fade col-md-12"
         id="filtreModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="filtreModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="filtreModalLabel">Paramètres de filtre</h5>
            <button type="button"
                    class="close"
                    ref="btn-close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ListFilter :champs="chmp"/>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-primary"
                    @click="filtrer()">filtrer</button>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal ajustement-->
    <div class="modal fade col-md-12"
         id="exampleModal2"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModal2Label"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="exampleModal2Label">Ajustement du budget</h5>
            <button type="button"
                    class="close"
                    ref="btn-close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form class="form">
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="">Budget:</label>
                  <input type="text"
                         class="form-control" 
                         name="" 
                         id=""
                         disabled
                         v-model="form.budget">
                </div>
                <div class="form-group col-md-6">
                  <label for="">Réalisation:</label>
                  <input type="text"
                         class="form-control" 
                         name="" 
                         id=""
                         disabled
                         v-model="form.realisation">
                </div>
              </div>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="">Ajuster le budget:</label>
                  <input type="text"
                         class="form-control" 
                         name="" 
                         id=""
                         v-model="ajustement_budget"
                         @input="addSpace('ajustement_budget')">
                </div>
                <div class="form-group col-md-6">
                  <label for="">Ajuster la réalisation:</label>
                  <input type="text"
                         class="form-control" 
                         name="" 
                         id=""
                         v-model="ajustement_realisation"
                         @input="addSpace('ajustement_realisation')">
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal">Annuler</button>
            <button type="button"
                    class="btn btn-primary"
                    @click="submit()">Enregistrer</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
#op{
  border: none;
  font-size: 0.7em;
  height: 5vh;
  background: none;
}
.bad-news{
  color:#ff0000;
}
.good-news{
  color:#24b13c;
}
.ajust{
  font-size: 0.85em;
}
</style>
<script>
import { mapActions,mapGetters,mapMutations } from "vuex"
import ListFilter from "@/components/shared/Filter"
import utils from "@/assets/js/utils"
import VueHtml2pdf from 'vue-html2pdf'

export default({
  name:"PlanComptableCG",
  props:["compte","year"],
  components:{
    ListFilter,
    VueHtml2pdf
  },
  data: ()=>({
    JournalData:{
      // type_comptabilite:"",
      annee_debut: new Date().getFullYear()+"-01-01",
      annee_fin: new Date().getFullYear()+"-12-31",
      classe:"",
      base:"",
      filtre:"",
      periode:"ANNUELLE"
    },
    ajustement_budget:"",
    ajustement_realisation:"",
    form:{
      id:"",
      ajustement_budget:"",
      ajustement_realisation:"",
      budget:"",
      realisation:"",
    },
    comptes:{
      pagination:"",
      donnees:[]
    },
    loaded:false,
    pIndex:1,
    filtre:[],
    chmp:[
      {
        valeur:"compte",
        type:"caractere",
        table:"plan_comptes",
        libelle:"Compte",
      },
      {
        valeur:"libelle",
        type:"caractere",
        table:"comptabilite_globales",
        libelle:"Libellé",
      },
      {
        valeur:"date",
        type:"date",
        table:"comptabilite_globales",
        libelle:"Date",
      },
      {
        valeur:"debit",
        type:"numeric",
        table:"comptabilite_globales",
        libelle:"Débit",
      },
      {
        valeur:"credit",
        type:"numeric",
        table:"comptabilite_globales",
        libelle:"Crédit",
      },
    ],
    total:{
      budget:0,
      realisation:0,
      ajustement_budget:0,
      budget_definitif:0,
      ajustement_realisation:0,
      ecart:0
    },
    dateJour: "",
    goodUrl:"",
    user:""
  }),
  created(){

    var jour = new Date()
    if (jour.getDate() < 10) {
      this.dateJour +="0" 
    }
    this.dateJour+=jour.getDate()+"-" 
    if (jour.getMonth() < 9) {
      this.dateJour +="0"
    }
    this.dateJour+=(jour.getMonth()+1)+"-"
    this.dateJour += jour.getFullYear()
    
    this.JournalData.annee_debut = this.year.annee_debut  
    this.JournalData.annee_fin = this.year.annee_fin  
    this.JournalData.base = this.year.base 
    
    this.loadJournalCG(this.JournalData)
    this.user = utils.getUserRole()
  },
  watch:{
    journalCG(){
      if (this.journalCG) {
        this.loaded=true
        this.comptes={
          pagination:"",
          donnees:[]
        }
        this.comptes.pagination=this.journalCG.pagination
        var count=0
        this.journalCG.donnees.forEach(journal => {
          this.comptes.donnees.push({
            ajustement_budget:journal.ajustement_budget,
            ajustement_realisation:journal.ajustement_realisation,
            budget:journal.budget,
            budget_definitif:journal.budget+journal.ajustement_budget,
            compte:journal.compte,
            credit:journal.credit,
            debit:journal.debit,
            id:journal.id,
            libelle:journal.libelle,
            realisation:journal.realisation,
            ecart:0,
            color:""
          })
          this.comptes.donnees[count].ecart= this.comptes.donnees[count].budget_definitif - this.comptes.donnees[count].realisation,
          this.comptes.donnees[count].color= this.comptes.donnees[count].budget_definitif - this.comptes.donnees[count].realisation < 0 ? "bad-news":"good-news"
          count++
        })

        this.$refs["btn-close"].click()
        this.setJournalCG("")
        this.total={
          budget:0,
          realisation:0,
          ajustement_budget:0,
          ajustement_realisation:0,
          budget_definitif:0,
          ecart:0,
        },
        this.comptes.donnees.forEach(compte => {
          this.total.ajustement_budget += compte.ajustement_budget
          this.total.ajustement_realisation += compte.ajustement_realisation
          this.total.budget += compte.budget
          this.total.budget_definitif += compte.budget_definitif
          this.total.realisation += compte.realisation
          this.total.ecart += compte.ecart
        })
        console.log(this.total)
      }
    },
    compte(){
      if (this.compte==true) {
        this.loaded=false
        this.loadJournalCG(this.JournalData)    
      }
    },
    msgSuccessAjustementCG(){
      if (this.msgSuccessAjustementCG) {
        this.setJournalCG("")
        this.loadJournalCG(this.JournalData)
        this.setMsgSuccessAjustementCG("")    
      }
    }
  },
  computed:{
    ...mapGetters(["journalCG","errorPlanMontant","msgSuccessAjustementCG","msgFailAjustementCG"])
  },
  methods:{
    ...mapActions(["loadJournalCG","pageJournalCG","ajusterJournalCG"]),
    ...mapMutations(["setJournalCG","setErrorPlanMontant","setFailAjustementCG","setMsgSuccessAjustementCG"]),
    search(){
      this.loaded=false
      this.JournalData.annee_debut = this.year.annee_debut 
      this.JournalData.annee_fin = this.year.annee_fin
      this.loadJournalCG(this.JournalData)
    },
    filtrer() {
      this.loaded = false
      this.long = null
      this.JournalData.filtre=this.filtre
      this.loadJournalCG(this.JournalData)
    },
    paging(url) {
      if (this.pIndex == 0) {
        this.pIndex = 1
      }
      this.goodUrl = url.split("/")
      url = this.goodUrl[1]+"&annee_debut="+this.JournalData.annee_debut+"&annee_fin="+this.JournalData.annee_fin+"&base="+this.JournalData.base
      this.loaded = false
      var count=1
      var used=0
      url+="&filtres=["  
      this.filtre.forEach(filtre => {
        if (filtre.type == "date") {
          if (filtre.operation!="" && filtre.valeur!="") {
            if (filtre.operation == "interval" && filtre.valeur!="" && filtre.valeur1!="") {
              if (count>1 && used !=0) {
                url+=","
              }
              url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","valeur1":"'
                +filtre.valeur1+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
              used+=1
            }else{
              if (count>1 && used !=0) {
                url+=","
              }
              url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","operation":"'
                +filtre.operation+'","valeur":"'+filtre.valeur+'","periode":"'+filtre.periode+'","type":"'
                +filtre.type+'"}'
              used+=1
            }
          }
        }else{
          if (filtre.valeur!="" && filtre.nature!="") {
            if (count>1 && used !=0) {
              url+=","
              console.log(count)
            }
            url+='{"libelle":"'+filtre.libelle+'","table":"'+filtre.table+'","nature":"'+filtre.nature+'","valeur":"'+filtre.valeur+'","type":"'+filtre.type+'"}'
            used+=1
          }
        }
        count+=1
      })
      url+="]"
      this.setJournalCG("")
      this.pageJournalCG(url)
    },
    ajustement(compte){
      console.log(compte)
      this.form.id=compte.id
      this.ajustement_budget=compte.ajustement_budget
      this.ajustement_realisation=compte.ajustement_realisation
      this.form.budget=compte.budget.toLocaleString()
      this.form.realisation=compte.realisation.toLocaleString()
      // this.ajusterJournalCG(this.form)
    },
    submit(){
      this.ajusterJournalCG(this.form)
    },
    addSpace(key) {
      var chaine = ""
      var splinter = this[key].split("")
      var res =""
      this.form[key]=""
      if (/[0-9]/.test(this[key]) || !/[A-Za-zéèàôêâîûöïüëä$&+,:;=?@#|"~{}'<>.^*()%!]/.test(this[key])) {
        for (let index = 0; index < splinter.length; index++) {
          if (index==0) {
            if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " " && splinter[index]!='-') {
              splinter[index] = ""
            }
          }else{
            if (!/[0-9]/.test(splinter[index]) && !splinter[index] == " ") {
              splinter[index] = ""
            }
          }
          chaine += splinter[index]
        }
        if (this[key].length >= 4) {
          // check if there's a space
          if (this[key].includes(" ")) {
            var trimer = chaine.split(" ")
            chaine = ""
            for (let index = 0; index < trimer.length; index++) {
              chaine += trimer[index]
            }
            chaine = chaine.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          } else {
            chaine = ""
            chaine = this[key].replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }
        }
      }
      this[key] = chaine
      res = this[key].split(" ")
      for (let index = 0; index < res.length; index++) {
        this.form[key] += res[index]
      }
      this.form[key]=Number(this.form[key])
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    },
  }
})
</script>
