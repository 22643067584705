<template>
  <table class="table"
         id="op">
    <thead>
      <tr>
        <th style="width:50%;border:none"></th>
        <th style="border:none"
            class="th-blue text-center"
            colspan="2">Exercice clos le 31 Décembre</th>
      </tr>
      <tr>
        <th style="width:50%;border:none"></th>
        <th style="width:25%"
            class="th-blue text-center">{{year.annee}}</th>
        <th style="width:25%"
            class="th-blue text-center">{{year.annee-1}}</th>
      </tr>
    </thead>
    <tbody v-if="charged">
      <tr>
        <td scope="row"><strong>PRODUITS D'EXPLOITATION</strong></td>
        <td class="text-right"></td>
        <td class="text-right"></td>
      </tr>
      <tr>
        <td scope="row">Revenus</td>
        <td class="text-right">{{result["annee_n"].Revenus.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Revenus.toLocaleString()}}</td>
      </tr>
      <tr>
        <td scope="row">Autres produits d'exploitation</td>
        <td class="text-right">{{result["annee_n"].Autres_produits_exploitations.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Autres_produits_exploitations.toLocaleString()}}</td>
      </tr>
      <tr>
        <td scope="row">Production immobilisée</td>
        <td class="text-right">{{result["annee_n"].Production_immobilisees.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Production_immobilisees.toLocaleString()}}</td>
      </tr>
      <tr class="tht-dark-blue">
        <td scope="row"><strong>Total des produits d'exploitation</strong></td>
        <td class="text-right"><strong>{{result["annee_n"].Total_produits_exploitations.toLocaleString()}}</strong></td>
        <td class="text-right"><strong>{{result["annee_n-1"].Total_produits_exploitations.toLocaleString()}}</strong></td>
      </tr>
      <tr>
        <td scope="row"><strong>CHARGES D'EXPLOITATION</strong></td>
        <td class="text-right"></td>
        <td class="text-right"></td>
        
      </tr>
      <tr>
        <td scope="row">Variation des stocks des produits finis et des encours (en+ou-)</td>
        <td class="text-right">{{result["annee_n"].Variation_des_stocks.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Variation_des_stocks.toLocaleString()}}</td>
      </tr>
      <tr>
        <td scope="row">Achats de marchandises consommés</td>
        <td class="text-right">{{result["annee_n"].Achat_marchandises.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Achat_marchandises.toLocaleString()}}</td>
      </tr>
      <tr>
        <td scope="row">Charges de personnel</td>
        <td class="text-right">{{result["annee_n"].Charges_de_personnels.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Charges_de_personnels.toLocaleString()}}</td>
      </tr>
      <tr>
        <td scope="row">Dotations aux amortissements et aux provisions (non financières)</td>
        <td class="text-right">{{result["annee_n"].Dotations_aux_amortissements.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Dotations_aux_amortissements.toLocaleString()}}</td>
      </tr>
      <tr>
        <td scope="row">Autres charges d'exploitation</td>
        <td class="text-right">{{result["annee_n"].Autres_charges_exploitations.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Autres_charges_exploitations.toLocaleString()}}</td>
      </tr>
      <tr class="tht-dark-blue">
        <td scope="row"><strong>Total des charges d'exploitation</strong></td>
        <td class="text-right"><strong>{{result["annee_n"].Total_charges_exploitations.toLocaleString()}}</strong></td>
        <td class="text-right"><strong>{{result["annee_n-1"].Total_charges_exploitations.toLocaleString()}}</strong></td>
      </tr>
      <tr>
        <td scope="row">Résultat d'exploitation</td>
        <td class="text-right">{{result["annee_n"].Resultat_exploitations.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Resultat_exploitations.toLocaleString()}}</td>
      </tr>
      <tr>
        <td scope="row">Charges financières nettes</td>
        <td class="text-right">{{result["annee_n"].Charges_financieres_nettes.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Charges_financieres_nettes.toLocaleString()}}</td>
      </tr>
      <tr>
        <td scope="row">Produits des placements</td>
        <td class="text-right">{{result["annee_n"].Produits_des_placements.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Produits_des_placements.toLocaleString()}}</td>
      </tr>
      <tr>
        <td scope="row">Autres gains ordinaires</td>
        <td class="text-right">{{result["annee_n"].Autres_gains_ordinaires.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Autres_gains_ordinaires.toLocaleString()}}</td>
      </tr>
      <tr>
        <td scope="row">Autres pertes ordinaires</td>
        <td class="text-right">{{result["annee_n"].Autres_pertes_ordinaires.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Autres_pertes_ordinaires.toLocaleString()}}</td>
      </tr>
      <tr class="tht-dark-blue">
        <td scope="row"><strong>Résultat des activités ordinaire avant impôt</strong></td>
        <td class="text-right"><strong>{{result["annee_n"].Resultat_des_activites_ordinaires_avant_impots.toLocaleString()}}</strong></td>
        <td class="text-right"><strong>{{result["annee_n-1"].Resultat_des_activites_ordinaires_avant_impots.toLocaleString()}}</strong></td>
      </tr>
      <tr>
        <td scope="row">Impôt sur les bénéfices</td>
        <td class="text-right">{{result["annee_n"].Impot_sur_les_benefices.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Impot_sur_les_benefices.toLocaleString()}}</td>
      </tr>
      <tr class="tht-dark-blue">
        <td scope="row"><strong>Résultat des activités ordinaire après impôt</strong></td>
        <td class="text-right"><strong>{{result["annee_n"].Resultat_des_activites_ordinaires_apres_impots.toLocaleString()}}</strong></td>
        <td class="text-right"><strong>{{result["annee_n-1"].Resultat_des_activites_ordinaires_apres_impots.toLocaleString()}}</strong></td>
      </tr>
      <tr>
        <td scope="row">Eléments extraordinaires (Gains/Pertes)</td>
        <td class="text-right">{{result["annee_n"].Elements_extraordinaires.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Elements_extraordinaires.toLocaleString()}}</td>
      </tr>
      <tr class="tht-dark-blue">
        <td scope="row"><strong>Résultat net de l'exercice</strong></td>
        <td class="text-right"><strong>{{result["annee_n"].Resultat_nets.toLocaleString()}}</strong></td>
        <td class="text-right"><strong>{{result["annee_n-1"].Resultat_nets.toLocaleString()}}</strong></td>
      </tr>
      <tr>
        <td scope="row">Effet des modifications comptables (net d'impôt)</td>
        <td class="text-right">{{result["annee_n"].Effet_des_modifications.toLocaleString()}}</td>
        <td class="text-right">{{result["annee_n-1"].Effet_des_modifications.toLocaleString()}}</td>
      </tr>
      <tr class="tht-dark-blue">
        <td scope="row"><strong>Résultat après modifications comptables</strong></td>
        <td class="text-right"><strong>{{result["annee_n"].Resultat_apres_modifications.toLocaleString()}}</strong></td>
        <td class="text-right"><strong>{{result["annee_n-1"].Resultat_apres_modifications.toLocaleString()}}</strong></td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr>
        <td scope="row"><strong>PRODUITS D'EXPLOITATION</strong></td>
        <td class="text-right"></td>
        <td class="text-right"></td>
      </tr>
      <tr>
        <td scope="row">Revenus</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr>
        <td scope="row">Autres produits d'exploitation</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr>
        <td scope="row">Production immobilisée</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr class="tht-dark-blue">
        <td scope="row"><strong>Total des produits d'exploitation</strong></td>
        <td class="text-right"><strong>0</strong></td>
        <td class="text-right"><strong>0</strong></td>
      </tr>
      <tr>
        <td scope="row"><strong>CHARGES D'EXPLOITATION</strong></td>
        <td class="text-right"></td>
        <td class="text-right"></td>
      </tr>
      <tr>
        <td scope="row">Variation des stocks des produits finis et des encours (en+ou-)</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr>
        <td scope="row">Achats de marchandises consommés</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr>
        <td scope="row">Charges de personnel</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr>
        <td scope="row">Dotations aux amortissements et aux provisions (non financières)</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr>
        <td scope="row">Autres charges d'exploitation</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr class="tht-dark-blue">
        <td scope="row"><strong>Total des charges d'exploitation</strong></td>
        <td class="text-right"><strong>0</strong></td>
        <td class="text-right"><strong>0</strong></td>
      </tr>
      <tr>
        <td scope="row">Résultat d'exploitation</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr>
        <td scope="row">Charges financières nettes</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr>
        <td scope="row">Produits des placements</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr>
        <td scope="row">Autres gains ordinaires</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr>
        <td scope="row">Autres pertes ordinaires</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr class="tht-dark-blue">
        <td scope="row"><strong>Résultat des activités ordinaire avant impôt</strong></td>
        <td class="text-right"><strong>0</strong></td>
        <td class="text-right"><strong>0</strong></td>
      </tr>
      <tr>
        <td scope="row">Impôt sur les bénéfices</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr class="tht-dark-blue">
        <td scope="row"><strong>Résultat des activités ordinaire après impôt</strong></td>
        <td class="text-right"><strong>0</strong></td>
        <td class="text-right"><strong>0</strong></td>
      </tr>
      <tr>
        <td scope="row">Eléments extraordinaires (Gains/Pertes)</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr class="tht-dark-blue">
        <td scope="row"><strong>Résultat net de l'exercice</strong></td>
        <td class="text-right"><strong>0</strong></td>
        <td class="text-right"><strong>0</strong></td>
      </tr>
      <tr>
        <td scope="row">Effet des modifications comptables (net d'impôt)</td>
        <td class="text-right">0</td>
        <td class="text-right">0</td>
      </tr>
      <tr class="tht-dark-blue">
        <td scope="row"><strong>Résultat après modifications comptables</strong></td>
        <td class="text-right"><strong>0</strong></td>
        <td class="text-right"><strong>0</strong></td>
      </tr>
    </tbody>
  </table>
</template>
<style scoped>
#op{
  border: none;
  font-size: 0.7em;
  height: 5vh;
  background: none;
}
</style>
<script>

import { mapActions,mapGetters,mapMutations } from "vuex"

export default ({
  name:"ResultatsCG",
  props:["year"],
  data:()=>({
    resultatData:{
      annee_debut:"",
      annee_fin:"",
      base:""
    },
    result:[],
    charged:false
  }),
  watch:{
    resultatCG(){
      if (this.resultatCG) {
        if (this.resultatCG["annee_n"]) {
          this.result["annee_n"]=this.resultatCG["annee_n"][0]
          this.result["annee_n-1"]=this.resultatCG["annee_n-1"][0]
          this.charged=true
          console.log(this.result)
        }else{
          if (this.resultatCG[0]) {
            this.result["annee_n"]=this.resultatCG[0]["annee_n"][0]
            // --------------------------------annee n------------------------------------------
            
            this.result["annee_n"].Total_produits_exploitations=this.result["annee_n"].Revenus+this.result["annee_n"].Autres_produits_exploitations+this.result["annee_n"].Production_immobilisees
           
            this.result["annee_n"].Total_charges_exploitations=this.result["annee_n"].Variation_des_stocks+
              this.result["annee_n"].Achat_marchandises+this.result["annee_n"].Charges_de_personnels+
              this.result["annee_n"].Dotations_aux_amortissements+this.result["annee_n"].Autres_charges_exploitations

            this.result["annee_n"].Resultat_exploitations= this.result["annee_n"].Total_produits_exploitations-this.result["annee_n"].Total_charges_exploitations
            this.result["annee_n"].Resultat_des_activites_ordinaires_avant_impots=this.result["annee_n"].Resultat_exploitations-this.result["annee_n"].Charges_financieres_nettes+
              this.result["annee_n"].Produits_des_placements+this.result["annee_n"].Autres_gains_ordinaires-
              this.result["annee_n"].Autres_pertes_ordinaires

            this.result["annee_n"].Resultat_des_activites_ordinaires_apres_impots=this.result["annee_n"].Resultat_des_activites_ordinaires_avant_impots-this.result["annee_n"].Impot_sur_les_benefices
            
            this.result["annee_n"].Resultat_nets=this.result["annee_n"].Resultat_des_activites_ordinaires_apres_impots+this.result["annee_n"].Elements_extraordinaires
            
            this.result["annee_n"].Resultat_apres_modifications=this.result["annee_n"].Resultat_nets-this.result["annee_n"].Effet_des_modifications

            // --------------------------------annee n-1----------------------------------------
            
            this.result["annee_n-1"]=this.resultatCG[0]["annee_n-1"][0]
            this.result["annee_n-1"].Total_produits_exploitations=this.result["annee_n-1"].Revenus+this.result["annee_n-1"].Autres_produits_exploitations+this.result["annee_n-1"].Production_immobilisees
           
            this.result["annee_n-1"].Total_charges_exploitations=this.result["annee_n-1"].Variation_des_stocks+
              this.result["annee_n-1"].Achat_marchandises+this.result["annee_n-1"].Charges_de_personnels+
              this.result["annee_n-1"].Dotations_aux_amortissements+this.result["annee_n-1"].Autres_charges_exploitations

            this.result["annee_n-1"].Resultat_exploitations= this.result["annee_n-1"].Total_produits_exploitations-this.result["annee_n-1"].Total_charges_exploitations
            this.result["annee_n-1"].Resultat_des_activites_ordinaires_avant_impots=this.result["annee_n-1"].Resultat_exploitations-this.result["annee_n-1"].Charges_financieres_nettes+
              this.result["annee_n-1"].Produits_des_placements+this.result["annee_n-1"].Autres_gains_ordinaires-
              this.result["annee_n-1"].Autres_pertes_ordinaires

            this.result["annee_n-1"].Resultat_des_activites_ordinaires_apres_impots=this.result["annee_n-1"].Resultat_des_activites_ordinaires_avant_impots-this.result["annee_n-1"].Impot_sur_les_benefices
            
            this.result["annee_n-1"].Resultat_nets=this.result["annee_n-1"].Resultat_des_activites_ordinaires_apres_impots+this.result["annee_n-1"].Elements_extraordinaires
            
            this.result["annee_n-1"].Resultat_apres_modifications=this.result["annee_n-1"].Resultat_nets-this.result["annee_n-1"].Effet_des_modifications
            this.charged=true
          }
        }
        console.log(this.resultatCG)
        this.setResultatCG("")
      }

    }
  },
  created(){
    this.result = []
    this.charged=false
    this.resultatData.annee_debut=this.year.annee_debut
    this.resultatData.annee_fin=this.year.annee_fin
    // this.resultatData.base=this.year.base
    this.loadResultatCG(this.resultatData)
  },
  computed:{
    ...mapGetters(["resultatCG","msgFailResultatCG"])
  },
  methods:{
    ...mapActions(["loadResultatCG",]),
    ...mapMutations(["setResultatCG","setFailResultatCG"]),

  }
})
</script>