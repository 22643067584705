<template>
  <table class="table table-striped">
    <thead>
      <tr>
        <th class="th-blue">Date</th>
        <th class="th-blue">Compte</th>
        <th class="th-blue">Libellé</th>
        <th class="th-blue">Débit</th>
        <th class="th-blue">Crédit</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td scope="row"></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td scope="row"></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default ({
  name:"JournalCG",
  data:()=>({

  }),
  computed:{
    ...mapGetters([])
  },
  methods:{
    ...mapActions([]),
    ...mapMutations([]),
  },
})
</script>
